import ApiOperations from "features/ApiOperations";
import config from "components/Common/config";
import setLoading from "features/helper";
import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";

export const uploadEntitiesToCM = async (currentUserID, url) => {
  setLoading(true);
  let finalUrl = url[url.length - 2].match(/(https?:\/\/[^\s]+)/)[1];

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/uploadTFtoCM/${currentUserID}`,
    { url: finalUrl },
    (status, data) => {
      return data;
    }
  );

  setLoading(false);
};

export const finalTraffickingSheetToGoogleSheet = async (payload, currentUserID) => {
  setLoading(true);
  let finalPayload = {};
  let finalSheetPayload = {};
  let sheetTabNames = [];

  ["Trafficking Sheet", "Traficking Sheet Metadata"].map((tab) => {
    if (payload[tab]) {
      finalSheetPayload[tab] = [];
      sheetTabNames.push(tab);
    }
    payload[tab]?.map((element) => {
      if (element.length > 0) {
        if (!element.every((value) => value === "empty" || value === "" || value === false)) {
          finalSheetPayload[tab].push(element);
        }
      }
    });
  });

  finalPayload["finalSheetPayload"] = finalSheetPayload;
  finalPayload["sheetTabNames"] = sheetTabNames;
  finalPayload["sheetName"] = "CMTM Destinations Config Sheet";

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/metadata_sheet_Uplaoder/${currentUserID}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const finalSheetDownloader = async (currentUserID, url) => {
  setLoading(true);
  let finalUrl = url[url.length - 2].match(/(https?:\/\/[^\s]+)/)[1];

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/getTrafickingSheetFinalData/${currentUserID}`,
    { url: finalUrl, range: "Traficking Sheet" },
    (status, data) => {
      return data;
    }
  );

  // const wb = XLSX.utils.book_new();

  let sortedArray = response.slice(1).sort((a, b) => {
    // Join the first three elements of each nested array into a single string for comparison
    let aKey = a.slice(0, 3).join(",");
    let bKey = b.slice(0, 3).join(",");

    // Compare the concatenated strings
    return aKey.localeCompare(bKey);
  });

  let siteMerge = [];
  let packageMerge = [];
  let siteStart = 0;
  let packageStart = 0;

  for (let i = 1; i < sortedArray.length; i++) {
    // Check if the first element changes
    if (sortedArray[i][0] !== sortedArray[i - 1][0]) {
      siteMerge.push({ start: siteStart, end: i - 1 });
      siteStart = i;
    }

    // Check if the second element changes
    if (sortedArray[i][2] !== sortedArray[i - 1][2]) {
      packageMerge.push({ start: packageStart, end: i - 1 });
      sortedArray.splice(i, 0, ["", "", "", "", "", "", "", "", "", ""]); // Insert an empty array after the current element
      packageStart = i + 1; // Update the start index for the next range
      i++; // Skip the newly inserted empty array
    }
  }

  // Handle the last ranges
  siteMerge.push({ start: siteStart, end: sortedArray.length - 1 });
  packageMerge.push({ start: packageStart, end: sortedArray.length - 1 });

  sortedArray.unshift(response[0]);

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Trafficking Sheet");

  sortedArray.forEach((row, rowIndex) => {
    row.forEach((cell, columnIndex) => {
      ws.getCell(rowIndex + 1, columnIndex + 1).value = cell;
    });
  });

  ws.getColumn("A").width = 15;
  ws.getColumn("B").width = 10;
  ws.getColumn("C").width = 30;
  ws.getColumn("D").width = 60;
  ws.getColumn("E").width = 15;
  ws.getColumn("F").width = 15;
  ws.getColumn("G").width = 15;
  ws.getColumn("K").width = 20;
  ws.getColumn("L").width = 60;
  ws.getColumn("O").width = 65;

  siteMerge.forEach((row) => {
    ws.mergeCells(row.start + 2, 1, row.end + 2, 1); // Column A merge
  });

  siteMerge.forEach((row) => {
    ws.mergeCells(row.start + 2, 2, row.end + 2, 2); // Column A merge
  });

  packageMerge.forEach((row) => {
    ws.mergeCells(row.start + 2, 3, row.end + 2, 3); // Column B merge

    const cellRange = ws.getCell(`C${row.start + 2}:C${row.end + 2}`);
    cellRange.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "000000" },
    };
    cellRange.font = { color: { argb: "FFFFFF" } };
  });

  ws.getColumn("A").alignment = { vertical: "middle", wrapText: true };
  ws.getColumn("B").alignment = { vertical: "middle", wrapText: true };
  ws.getColumn("C").alignment = { vertical: "middle", wrapText: true };
  ws.getColumn("D").alignment = { vertical: "middle", wrapText: true };

  // Set background color for specific cells
  const cellsWithColor = {
    A1: { bg: "ffe1cc" },
    B1: { bg: "ffe1cc" },
    C1: { bg: "ffe1cc" },
    D1: { bg: "ffe1cc" },
    E1: { bg: "000000", font: "FFFFFF" },
    F1: { bg: "000000", font: "FFFFFF" },
    G1: { bg: "000000", font: "FFFFFF" },
    H1: { bg: "ffe1cc" },
    I1: { bg: "ffe1cc" },
    J1: { bg: "ffe1cc" },
    K1: { bg: "ffe1cc" },
    L1: { bg: "ffe1cc" },
    M1: { bg: "ffe1cc" },
    O1: { bg: "ffe1cc" },
    N1: { bg: "c5e0b3" },
    P1: { bg: "c5e0b3" },
    Q1: { bg: "c5e0b3" },
    R1: { bg: "c5e0b3" },
    S1: { bg: "c5e0b3" },
    T1: { bg: "c5e0b3" },
  };

  for (const key in cellsWithColor) {
    const cell = ws.getCell(key);
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: cellsWithColor[key]["bg"] }, // Dark gray background color
    };
    if (cellsWithColor[key]["font"]) cell.font = { color: { argb: cellsWithColor[key]["font"] } };
  }

  // Save the workbook to a buffer
  wb.xlsx.writeBuffer().then((buffer) => {
    // Use the buffer for further processing or saving
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Yuniter Traficking Sheet - Final.xlsx`);
  });

  setLoading(false);
};

export const uploadAssetsToCM360API = async (
  currentUser,
  filestoUpload,
  advertiserId,
  profileId
) => {
  setLoading(true);
  let payload = {
    advertiserId: advertiserId,
    profileId: profileId,
  };

  let stringPayload = JSON.stringify(payload);

  const finalPayload = new FormData();
  finalPayload.append("stringPayload", stringPayload);
  finalPayload.append("files", filestoUpload);

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/upload_cm_creatives_to_cm/${currentUser}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );

  setLoading(false);
  return response;
};

export const traficking_sheet_Generator = async (
  placementMetadata,
  assetNames,
  placementPattern,
  currentUser,
  filestoUpload,
  advertiserId,
  profileId
) => {
  setLoading(true);
  let payload = {
    placementMetadata: placementMetadata,
    assetNames: assetNames,
    placementPattern: placementPattern,
    advertiserId: advertiserId,
    profileId: profileId,
  };

  let stringPayload = JSON.stringify(payload);

  const finalPayload = new FormData();
  finalPayload.append("stringPayload", stringPayload);
  finalPayload.append("files", filestoUpload);
  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/traficking_sheet_Generator/${currentUser}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  // console.log('response')
  // console.log(response)
  return response;
};

export const finalSheetUpload = async (payload, currentUser) => {
  setLoading(true);
  const sheetTabNames = [
    "Inputs - Placements",
    "Inputs - Creative Assets",
    "S1 - Flight Details",
    "S2 - Creative - Theme Mapping",
    "S3 - Placement Name Setup",
    "S4 - Placement Name Audit",
    "S5 - CP Configs",
    "S6 - Creative Flight Mapping",
    "S8 - Site Mapping",
    "S9 - Campaign Setup",
    "S7 - Audience Placement Mapping",
  ];

  let finalPayload = {};
  let finalSheetPayload = {};

  sheetTabNames.map((tab) => {
    finalSheetPayload[tab] = [];
    payload[tab]?.map((element) => {
      if (element.length > 0) {
        if (!element.every((value) => value === "empty" || value === "" || value === false)) {
          finalSheetPayload[tab].push(element);
        }
      }
    });
  });

  finalPayload["finalSheetPayload"] = finalSheetPayload;
  finalPayload["sheetTabNames"] = sheetTabNames;
  finalPayload["sheetName"] = "Final Generated Trafficking Sheet";

  const response = await ApiOperations.post(
    `${config.apiGateway.tenantURL}/api/v1/users/users/metadata_sheet_Uplaoder/${currentUser}`,
    finalPayload,
    (status, data) => {
      return data;
    }
  );
  setLoading(false);
  return response;
};

export const headerColor = {
  green: "EBF1DE",
  orange: "FDE9D9",
  blue: "DCE6F1",
};

export const styleHeader = (header: string[], color = headerColor.orange): any[][] => [
  header.map((col) => ({
    v: col,
    t: "s",
    s: {
      font: {
        bold: true,
      },
      alignment: { wrapText: true },
      fill: { fgColor: { rgb: color } },
    },
  })),
  header.map((col) => ({ wch: col.length * 3 })),
];

export const styleCells = (records: string[][]) =>
  records.map((row) =>
    row.map((cell) => ({
      t: "s",
      v: cell,
      s: { alignment: { wrapText: true } },
    }))
  );
