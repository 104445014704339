import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import { DafaultButton } from 'components/Common/Button';
import React, { useCallback, useMemo, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const objectPosition = {
  category: 0,
  values: 1,
};

const list2Object = (list: [string, string][]) => JSON.stringify(list.reduce((total, [key, value]) => ({ ...total, [key]: value }), {}));

const CategoriesInput = ({ label, fieldData }: any) => {
  const [showDetails, setShowDetails] = useState(false);

  const categoriesValue = useMemo(() => {
    let formattedValue = {};
    try {
      formattedValue = JSON.parse(fieldData?.fieldValues[fieldData?.index][fieldData?.fieldName]) ?? {};
    } catch (error) {}

    return Object.entries(formattedValue);
  }, [fieldData?.fieldValues]);
  const onCreateCategories = useCallback(() => {
    fieldData?.setFieldValues((prev) => {
      const formValues = JSON.parse(JSON.stringify(prev));
      let currentValue = JSON.parse(formValues[fieldData.index][fieldData.fieldName]);
      currentValue = { ...currentValue, [`#${categoriesValue.length}`]: '' };
      formValues[fieldData.index][fieldData.fieldName] = JSON.stringify(currentValue);
      return formValues;
    });
  }, [fieldData, categoriesValue]);

  const onChange = (index, type, value) => {
    fieldData?.setFieldValues((prev) => {
      const formValues = JSON.parse(JSON.stringify(prev));
      let currentValue: [string, string][] = Object.entries(JSON.parse(formValues[fieldData.index][fieldData.fieldName]));

      currentValue[index][objectPosition[type]] = type === 'category' ? `${value}#${index}` : value;
      formValues[fieldData.index][fieldData.fieldName] = list2Object(currentValue);
      return formValues;
    });
  };

  const onClear = (key) => {
    fieldData?.setFieldValues((prev) => {
      const formValues = JSON.parse(JSON.stringify(prev));
      let currentValue = JSON.parse(formValues[fieldData.index][fieldData.fieldName]);

      delete currentValue[`${key}`];

      formValues[fieldData.index][fieldData.fieldName] = JSON.stringify(currentValue);
      return formValues;
    });
  };

  return (
    <div className="my-4">
      <div className="flex items-center text-sm my-1">
        <p className="font-semibold mr-2 min-w-max">{label}</p>
        <button className="cursor-pointer mr-2" onClick={() => setShowDetails((prev) => !prev)} type="button">
          <InfoOutlinedIcon style={{ fontSize: '1rem' }} />
        </button>
        {showDetails ? <p className="font-medium text-[#808080]">{fieldData?.description}</p> : null}
      </div>
      {categoriesValue.map(([key, values], index) => (
        <div className="flex justify-between gap-2 mb-2">
          <div className="w-3/12 border border-primary rounded-md px-3 py-1 items-center">
            <input
              name="io_category"
              value={key.split('#')[0]}
              placeholder="Category"
              onChange={(e) => onChange(index, 'category', e.currentTarget.value)}
              className="w-full border-0 focus:outline-none text-xs text-primary p-0"
              style={{ WebkitAppearance: 'none', MozAppearance: 'textfield', outline: 'none' }}
            />
          </div>
          <div className="w-8/12 border border-primary rounded-md px-3 py-1 items-center">
            <input
              name="io_ids"
              value={values + ''}
              placeholder="Insertion Order Ids with comma delimiter (eg: Id1,Id2,Id3)"
              onChange={(e) => onChange(index, 'values', e.currentTarget.value)}
              className="w-full border-0 focus:outline-none text-xs text-primary p-0"
              style={{ WebkitAppearance: 'none', MozAppearance: 'textfield', outline: 'none' }}
            />
          </div>
          <div className="w-1/12">
            <IconButton onClick={() => onClear(key)}>
              <HighlightOffIcon />
            </IconButton>
          </div>
        </div>
      ))}
      <DafaultButton disabled={categoriesValue.length > 20} text="Add category" color={'info'} textColor={'#FFFFFF'} radius={'6px'} onClick={onCreateCategories} />
    </div>
  );
};

export default CategoriesInput;
