export const defaultMappings = {
  DV360: {
    B3: ["IO", "-", "LI", "Creative"],
    B4: ["LI", "-", "IO", "Creative"],
    B5: ["-", "IO", "LI", "Creative"],
    B6: ["Creative", "-", "IO", "LI"],
  },
  TradeDesk: {
    B3: ["-", "Ad Group", "Creative"],
    B4: ["-", "Ad Group", "Creative"],
    B5: ["Ad Group", "Creative", "-"],
    B6: ["Creative", "Ad Group", "Ad", "-"],
  },
  Meta: {
    B3: ["-", "Ad Set", "Ad"],
    B4: ["Ad Set", "-", "Ad"],
    B5: ["-", "Ad", "Ad Set"],
    B6: ["Ad", "-", "Ad Set"],
  },
  Quantcast: {
    B3: ["-", "Ad Set"],
    B4: ["Ad Set", "-"],
    B5: ["-", "Ad Set"],
    B6: ["-", "Ad Set"],
  },
  "Apex Exchange": { B3: [], B4: [], B5: [], B6: [] },
  Amazon: {
    B3: ["-", "Ad Group", "Creative"],
    B4: ["-", "Ad Group", "Creative"],
    B5: ["Ad Group", "Creative", "-"],
    B6: ["Creative", "-", "Ad Group"],
  },
  Verizon: { B3: [], B4: [], B5: [], B6: [] },
  TikTok: {
    B3: ["-", "Ad Group", "Ad", "Creative"],
    B4: ["Ad Group", "-", "Ad", "Creative"],
    B5: ["-", "Ad", "Ad Group", "Creative"],
    B6: ["Ad", "-", "Ad Group", "Creative"],
  },
  LinkedIn: {
    B3: ["Campaign Group", "Campaign", "Creative", "-"],
    B4: ["Campaign", "-", "Campaign Group", "Creative"],
    B5: ["-", "Campaign", "Campaign Group", "Creative"],
    B6: ["Creative", "-", "Campaign", "Campaign Group"],
  },
  Reddit: {
    B3: ["-", "Ad Group", "Ad", "Post"],
    B4: ["Ad Group", "-", "Ad", "Post"],
    B5: ["Ad", "-", "Ad Group", "Post"],
    B6: ["Post", "-", "Ad Group", "Ad"],
  },
};

export const entityMapping = [
  ["NOTE: Keep Entity Mapping empty in case no mapping required"],
  ["Entities Mapping"],
  ["Placement Group"],
  ["Placement"],
  ["Ad"],
  ["Creative"],
  [],
];

export const configSheetMetadata = {
  DV360: [
    { v: "DV360 Metadata" },
    { v: "Advertiser ID*" },
    { v: "Campaign Name*" },
    {
      v: "entityStatus*",
      dropdpwnOptions: ["ENTITY_STATUS_ACTIVE", "ENTITY_STATUS_PAUSED"],
    },
    {
      v: "campaignGoalType*",
      dropdpwnOptions: [
        "CAMPAIGN_GOAL_TYPE_APP_INSTALL	",
        "CAMPAIGN_GOAL_TYPE_BRAND_AWARENESS",
        "CAMPAIGN_GOAL_TYPE_OFFLINE_ACTION",
        "CAMPAIGN_GOAL_TYPE_ONLINE_ACTION",
      ],
    },
    {
      v: "performanceGoalType*",
      dropdpwnOptions: [
        "PERFORMANCE_GOAL_TYPE_CPM",
        "PERFORMANCE_GOAL_TYPE_CPC",
        "PERFORMANCE_GOAL_TYPE_CPA",
        "PERFORMANCE_GOAL_TYPE_CPIAVC",
        "PERFORMANCE_GOAL_TYPE_CTR",
        "PERFORMANCE_GOAL_TYPE_VIEWABILITY",
        "PERFORMANCE_GOAL_TYPE_OTHER",
      ],
    },
    { v: "performanceGoalAmountMicros*" },
    { v: "Campaign Start Date*" },
    { v: "Campaign End Date*" },
  ],
  Quantcast: [
    { v: "Quantcast Metadata" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Campaign Goal Type" },
    { v: "Campaign Goal Value" },
    { v: "Campaign Status" },
    { v: "Campaign Start Date" },
    { v: "Campaign End Date" },
    { v: "Reference Number" },
    { v: "Daily Budget" },
  ],
  TradeDesk: [
    { v: "TradeDesk Metadata" },
    { v: "API Token" },
    { v: "Partner ID" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Budget" },
    { v: "Campaign Objective" },
    { v: "Primary Goal" },
    { v: "Primary Channel" },
    { v: "Secondary Goal" },
    { v: "Tertiary Goal" },
    { v: "Conversion Reporting Columns" },
    { v: "Availability" },
    { v: "Campaign Type" },
    { v: "Campaign Description" },
    { v: "Pacing Mode" },
  ],
  Meta: [
    { v: "Meta Metadata" },
    { v: "Access Token" },
    { v: "App ID" },
    { v: "App Secret" },
    { v: "Ad Account ID" },
    { v: "Campaign Name" },
    { v: "Objective" },
    { v: "Status" },
    { v: "Page ID" }
  ],
  "Apex Exchange": [
    { v: "Apex Exchange Metadata" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Campaign Goal Type" },
    { v: "Campaign Goal Value" },
    { v: "Campaign Status" },
    { v: "Campaign Start Date" },
    { v: "Campaign End Date" },
    { v: "Reference Number" },
    { v: "Daily Budget" },
  ],
  Amazon: [
    { v: "Amazon Metadata" },
    { v: "Account ID" },
    { v: "Advertising API ClientId" },
    { v: "Advertiser Id" },
    { v: "Campaign Name" },
    { v: "Flights Start DateTime" },
    { v: "Flights Budget Amount" },
    { v: "Flights End DateTime" },
    { v: "Frequencies FrequencyType" },
    { v: "Frequencies Time UnitCount" },
    { v: "Frequencies Max Impressions" },
    { v: "Frequencies Time Unit" },
  ],
  Verizon: [
    { v: "Verizon Metadata" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Campaign Goal Type" },
    { v: "Campaign Goal Value" },
    { v: "Campaign Status" },
    { v: "Campaign Start Date" },
    { v: "Campaign End Date" },
    { v: "Reference Number" },
    { v: "Daily Budget" },
  ],
  TikTok: [
    { v: "TikTok Metadata" },
    { v: 'App ID*' },
    { v: 'Access Token*' },
    { v: 'Advertiser ID*' },
    { v: 'Campaign Type*',
      dropdpwnOptions: [
        "REGULAR_CAMPAIGN",
        "IOS14_CAMPAIGN"
      ]
     },
    { v: 'Campaign Name*' },
    { v: 'Objective Type*',
      dropdpwnOptions: [
        "REACH",
        "TRAFFIC",
        "VIDEO_VIEWS",
        "LEAD_GENERATION",
        "ENGAGEMENT",
        "APP_PROMOTION",
        "WEB_CONVERSIONS",
        "PRODUCT_SALES",
        "RF_REACH"
      ]
     },
    { v: 'App Promotion Type',
      dropdpwnOptions: [
        "",
        "APP_INSTALL",
        "APP_RETARGETING",
        "APP_PREREGISTRATION",
      ]
     },
    { v: 'Is Advanced Dedicated Campaign?',
      dropdpwnOptions: [
        "true",
        "false"
      ]
     },
    { v: 'Campaign App Profile Page State',
      dropdpwnOptions: [
        "ON",
        "OFF"
      ]
     },
    { v: 'RF Campaign Type',
      dropdpwnOptions: [
        "STANDARD",
        "PULSE"
      ]
     },
    { v: 'Campaign Product Source',
      dropdpwnOptions: [
        "",
        "CATALOG",
        "STORE"
      ]
     },
    { v: 'Special industries',
      dropdpwnOptions: [
        "HOUSING",
        "EMPLOYMENT",
        "CREDIT"
      ]
     },
    { v: 'Budget Optimize On',
      dropdpwnOptions: [
        "True",
        "False"
      ]
     },
    { v: 'Budget Mode',
      dropdpwnOptions: [
        "BUDGET_MODE_INFINITE",
        "BUDGET_MODE_TOTAL",
        "BUDGET_MODE_DAY",
      ]
     },
    { v: 'Budget' },
    { v: 'Operation Status',
      dropdpwnOptions: [
        "DISABLE",
        "ENABLE"
      ]
     },
    { v: 'Postback Window Mode',
      dropdpwnOptions: [
        "",
        "POSTBACK_WINDOW_MODE1",
        "POSTBACK_WINDOW_MODE2",
        "POSTBACK_WINDOW_MODE3"
      ]
     },
  ],
  LinkedIn: [
    { v: "LinkedIn Metadata" },
    { v: "Access Token*" },
    { v: "Account ID*" },
    { v: "Company Name*" },
  ],
  Reddit: [
    { v: "Reddit Metadata" },
    { v: "Access Token" },
    { v: "Account ID" },
    { v: "Campaign Name" },
    { v: "Configured Status" },
    { v: "Objective" },
  ],
  Insight: [
    { v: "Insight Metadata" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Campaign Goal Type" },
    { v: "Campaign Goal Value" },
    { v: "Campaign Status" },
    { v: "Campaign Start Date" },
    { v: "Campaign End Date" },
    { v: "Reference Number" },
    { v: "Daily Budget" },
  ],
  Autotrader: [
    { v: "Autotrader Metadata" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Campaign Goal Type" },
    { v: "Campaign Goal Value" },
    { v: "Campaign Status" },
    { v: "Campaign Start Date" },
    { v: "Campaign End Date" },
    { v: "Reference Number" },
    { v: "Daily Budget" },
  ],
  "Royal LePage": [
    { v: "Royal LePage Metadata" },
    { v: "Advertiser ID" },
    { v: "Campaign Name" },
    { v: "Campaign Goal Type" },
    { v: "Campaign Goal Value" },
    { v: "Campaign Status" },
    { v: "Campaign Start Date" },
    { v: "Campaign End Date" },
    { v: "Reference Number" },
    { v: "Daily Budget" },
  ]
};

export const supportedDestinations = [
  "DV360",
  "Quantcast",
  "TradeDesk",
  "Meta",
  "Apex Exchange",
  "Amazon",
  "Verizon",
  "TikTok",
  "LinkedIn",
  "Reddit",
  "Insight",
  "Autotrader",
  "Royal LePage"
];

export const config1 = {
  DV360: [
    { v: "IO Config Defaults" },
    { v: "Insertion Order Start Date (MM/DD/YYYY)*" },
    { v: "Insertion Order End Date (MM/DD/YYYY)*" },
    {
      v: "Insertion Order Type",
      dropdpwnOptions: ["RTB", "OVER_THE_TOP"],
    },
    {
      v: "PacingPeriod*",
      dropdpwnOptions: ["PACING_PERIOD_DAILY", "PACING_PERIOD_FLIGHT"],
    },
    {
      v: "PacingType*",
      dropdpwnOptions: ["PACING_TYPE_EVEN", "PACING_TYPE_ASAP", "PACING_TYPE_AHEAD"],
    },
    { v: "Daily Max Micros*" },
    { v: "Daily Max Impressions" },
    {
      v: "FrequencyCap Unlimited*",
      dropdpwnOptions: ["TRUE", "FALSE"],
    },
    {
      v: "FrequencyCap TimeUnit*",
      dropdpwnOptions: [
        "TIME_UNIT_LIFETIME",
        "TIME_UNIT_MONTHS",
        "TIME_UNIT_WEEKS",
        "TIME_UNIT_DAYS",
        "TIME_UNIT_HOURS",
        "TIME_UNIT_MINUTES",
      ],
    },
    { v: "FrequencyCap TimeUnitCount" },
    { v: "FrequencyCap MaxImpressions" },
    { v: "FrequencyCap maxViews" },
    {
      v: "KpiType*",
      dropdpwnOptions: [
        "KPI_TYPE_CPM",
        "KPI_TYPE_CPC",
        "KPI_TYPE_CPA",
        "KPI_TYPE_CTR",
        "KPI_TYPE_VIEWABILITY",
        "KPI_TYPE_CPIAVC",
        "KPI_TYPE_CPE",
        "KPI_TYPE_CPV",
        "KPI_TYPE_CLICK_CVR",
        "KPI_TYPE_IMPRESSION_CVR",
        "KPI_TYPE_VCPM",
        "KPI_TYPE_VTR",
        "KPI_TYPE_AUDIO_COMPLETION_RATE",
        "KPI_TYPE_VIDEO_COMPLETION_RATE",
        "KPI_TYPE_CPCL",
        "KPI_TYPE_CPCV",
        "KPI_TYPE_TOS10",
        "KPI_TYPE_MAXIMIZE_PACING",
        "KPI_TYPE_CUSTOM_IMPRESSION_VALUE_OVER_COST",
        "KPI_TYPE_OTHER",
      ],
    },
    { v: "kpiAlgorithmId" },
    { v: "kpiAmountMicros" },
    { v: "kpiPercentageMicros" },
    { v: "kpiString" },
    {
      v: "Budget Unit*",
      dropdpwnOptions: ["BUDGET_UNIT_CURRENCY", "BUDGET_UNIT_IMPRESSIONS"],
    },
    {
      v: "Budget AutomationType",
      dropdpwnOptions: [
        "INSERTION_ORDER_AUTOMATION_TYPE_BUDGET",
        "INSERTION_ORDER_AUTOMATION_TYPE_NONE",
        "INSERTION_ORDER_AUTOMATION_TYPE_BID_BUDGET",
      ],
    },
    { v: "BudgetSegment Amount*" },
    { v: "BudgetSegment Description" },
    { v: "BudgetSegment StartDate" },
    { v: "BudgetSegment EndDate" },
    { v: "BudgetSegment CampaignBudgetId" },
    { v: "BiddingStrategy FixedBidStrategy bidAmountMicros*" },
  ],
  Quantcast: [
    { v: "Ad Set Config Defaults" },
    { v: "Budget Type*", dropdpwnOptions: ["Daily Budget", "Lifetime Budget"] },
    { v: "Ad Set Start Date" },
    { v: "Ad Set End Date" },
    {
      v: "Bidding Type",
      dropdpwnOptions: ["Automatically optimized eCPM", "eCPM Cap", "eCPM Target"],
    },
    { v: "Cookie Type", dropdpwnOptions: ["Cookies", "Cookieless", "All"] },
  ],
  TradeDesk: [
    { v: "Ad Group Name*" },
    { v: "Ad Group Category" },
    { v: "Budget*" },
    { v: "Pacing Mode*" },
    { v: "Base Bid CPM Amount*" },
    { v: "Max Bid CPM Amount*" },
    { v: "Currency Code*" },
    { v: "Bid Lists" },
    { v: "Auto Allocator Enabled*", dropdpwnOptions: ["true", "false"] },
    { v: "Auto Prioritization Enabled*", dropdpwnOptions: ["true", "false"] },
  ],
  Meta: [
    { v: "Ad Set Config Defaults" },
    {
      v: "Optimization Goal*",
      dropdpwnOptions: [
        "REACH",
        "APP_INSTALLS",
        "AD_RECALL_LIFT",
        "OFFSITE_CONVERSIONS",
        "EVENT_RESPONSES",
        "LEAD_GENERATION",
        "LINK_CLICKS",
        "ENGAGED_USERS",
        "CONVERSATIONS",
        "PAGE_LIKES",
        "POST_ENGAGEMENT",
        "OFFSITE_CONVERSIONS",
        "THRUPLAY",
        "IMPRESSIONS",
        "VALUE",
        "QULITY_LEAD",
        "QUALITY_CALL",
        "LANDING_PAGE_VIEWS",
      ],
    },
    {
      v: "Billing Event*",
      dropdpwnOptions: [
        "IMPRESSIONS",
        "LINK_CLICKS",
        "THRUPLAY",
        "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
        "VIDEO_VIEWS",
      ],
    },
    { v: "Bid Amount*" },
    { v: "Daily Budget*" },
    { v: "Status*", dropdpwnOptions: ["paused", "active"] },
  ],
  "Apex Exchange": [
    { v: "Package Config Defaults	" },
    { v: "Placement Dimension" },
    { v: "Package Compatibilty" },
    { v: "Package Start Date" },
    { v: "Package End Date" },
    { v: "Hard Cutoff" },
    { v: "Geo targeting" },
    { v: "Package Startegy" },
    { v: "Content Category" },
    { v: "DV Wrapping" },
  ],
  Amazon: [
    { v: "Ad Group Config Defaults" },
    { v: "inventoryType*" },
    {
      v: "creativeRotationType*",
      dropdpwnOptions: [
        "STANDARD_DISPLAY",
        "AMAZON_MOBILE_DISPLAY",
        "AAP_MOBILE_APP",
        "DISPLAY",
        "VIDEO",
      ],
    },
    { v: "advertisedProductCategoryIds*" },
    { v: "frequencies frequencyType*", dropdpwnOptions: ["UNCAPPED", "CUSTOM"] },
    { v: "frequencies maximpressions" },
    { v: "frequencies timeUnitCount" },
    { v: "frequencies timeUnit", dropdpwnOptions: ["DAYS", "HOURS"] },
    { v: "pacing deliveryProfile", dropdpwnOptions: ["EVEN", "PACE_AHEAD", "ASAP"] },
    { v: "pacing catchUpBoostPercentage" },
    {
      v: "targetingSettings amazonViewability viewabilityTier",
      dropdpwnOptions: [
        "ALL_TIERS",
        "GREATER_THAN_70_PERCENT",
        "GREATER_THAN_60_PERCENT",
        "GREATER_THAN_50_PERCENT",
        "GREATER_THAN_40_PERCENT",
        "LESS_THAN_40_PERCENT",
      ],
    },
    {
      v: "targetingSettings amazonViewability includeUnmeasurableImpressions*",
      dropdpwnOptions: [false, true],
    },
    { v: "targetingSettings timeZoneType*", dropdpwnOptions: ["VIEWER", "ADVERTISER_REGION"] },
    {
      v: "targetingSettings userLocationSignal*",
      dropdpwnOptions: ["CURRENT", "MULTIPLE_SIGNALS"],
    },
    { v: "optimization bidStrategy*" },
    { v: "optimization automateBudgetAllocation" },
    { v: "bid baseBid*" },
    { v: "bid maxAverageCPM*" },
    { v: "startDateTime*" },
    { v: "budgetAmount" },
    { v: "endDateTime*" },
  ],
  Verizon: [
    { v: "Placement Config Defaults" },
    { v: "Media Type", dropdpwnOptions: ["DISPLAY", "VIDEO", "AUDIO"] },
    { v: "Status", dropdpwnOptions: ["Active", "Paused", "Inactive"] },
    { v: "Goal Type", dropdpwnOptions: ["None", "CPI", "CPC", "CPA", "CPSV", "VCPM", "ROAS", "OCPC"] },
    { v: "Goal Amount" },
    { v: "Bid Price" },
    { v: "Max Goal" },
    { v: "Margin Goal" },
    { v: "Pacing Acceleration" },
    { v: "Billing Method Type", dropdpwnOptions: ["CPM_PRICE", "CPC", "MARGIN", "CPCV"] },
  ],
  LinkedIn: [
    { v: "Campaign Group Config Defaults" },
    { v: "runSchedule start*" },
    { v: "runSchedule end" },
    { v: "totalBudget amount" },
    { v: "totalBudget currencyCode" },
    { v: "status*", dropdpwnOptions: ["DRAFT", "ACTIVE", "PAUSED", "ARCHIVED"] },
  ],
  Reddit: [
    { v: "Ad Group Config Defaults" },
    { v: "app_id" },
    { v: "bid_strategy*", dropdpwnOptions: ["BIDLESS", "MANUAL_BIDDING", "MAXIMIZE_VOLUME"] },
    { v: "bid_type*", dropdpwnOptions: ["CPC", "CPM", "CPV"] },
    { v: "bid_value" },
    { v: "configured_status", dropdpwnOptions: ["ACTIVE", "ARCHIVED", "DELETED", "PAUSED"] },
    {
      v: "effective_status",
      dropdpwnOptions: [
        "ACTIVE",
        "AD_GROUP_PAUSED",
        "ARCHIVED",
        "CAMPAIGN_PAUSED",
        "COMPLETED",
        "DELETED",
        "PAUSED",
        "PENDING_APPROVAL",
        "PENDING_BILLING_INFO",
        "PENDING_ID_VERIFICATION",
        "REJECTED",
      ],
    },
    { v: "end_time" },
    { v: "goal_type", dropdpwnOptions: ["DAILY_SPEND", "LIFETIME_SPEND"] },
    { v: "goal_value" },
    {
      v: "optimization_goal",
      dropdpwnOptions: [
        "ADD_TO_CART",
        "ADD_TO_WISHLIST",
        "CLICKS",
        "LEAD",
        "PAGE_VISIT",
        "PURCHASE",
        "SEARCH",
        "SIGN_UP",
        "UNKNOWN",
        "VIEW_CONTENT",
      ],
    },
    { v: "schedule end_day" },
    { v: "schedule end_hour" },
    { v: "schedule start_day" },
    { v: "schedule start_hour" },
    { v: "skadnetwork_metadata status", dropdpwnOptions: ["COOLDOWN", "DISABLED", "ENABLED"] },
    { v: "start_time" },
    { v: "targeting carries" },
    { v: "targeting communities" },
    { v: "targeting custom_audience_ids" },
    { v: "targeting devices" },
    { v: "targeting excluded_communities" },
    { v: "targeting excluded_custom_audience_ids" },
    { v: "targeting excluded_geolocations" },
    { v: "targeting excluded_interests" },
    { v: "targeting excluded_keywords" },
    { v: "targeting expand_targeting" },
    { v: "targeting gender" },
    { v: "targeting geolocations" },
    { v: "targeting interests" },
    { v: "targeting keyowrds" },
    { v: "targeting locations" },
    { v: "targeting suppression_event_types" },
    { v: "targeting view_modes" },
    {
      v: "view_through_conversions",
      dropdpwnOptions: ["SEVEN_DAY_CLICKS", "SEVEN_DAY_CLICKS_ONE_DAY_VIEW", null],
    },
  ],
  TikTok: [
    { v: "Config Defaults" },
    { v: "Shopping Ads Type", dropdpwnOptions: ["", "VIDEO", "LIVE", "PRODUCT_SHOPPING_ADS"] },
    { v: "Identity ID" },
    { v: "Identity Type", dropdpwnOptions: ["CUSTOMIZED_USER", "AUTH_CODE", "TT_USER", "BC_AUTH_TT"] },
    { v: "Identity Authorized Business Center ID" },
    { v: "Product Source", dropdpwnOptions: ["", "UNSET", "CATALOG", "STORE", "SHOWCASE"] },
    { v: "Catalog ID" },
    { v: "Catalog Authorized Business Center ID" },
    { v: "Store ID" },
    { v: "Store Authorized Business Center ID" },
    {
      v: "Promotion Type",
      dropdpwnOptions: [
        "",
        "APP_ANDROID",
        "APP_IOS",
        "WEBSITE",
        "LEAD_GENERATION",
        "LEAD_GEN_CLICK_TO_TT_DIRECT_MESSAGE",
        "LEAD_GEN_CLICK_TO_SOCIAL_MEDIA_APP_MESSAGE",
        "LEAD_GEN_CLICK_TO_CALL",
        "WEBSITE_OR_DISPLAY",
        "TIKTOK_SHOP",
        "VIDEO_SHOPPING",
      ],
    },
    { v: "Promotion Target Type", dropdpwnOptions: ["", "INSTANT_PAGE", "EXTERNAL_WEBSITE"] },
    { v: "Promotion Website Type", dropdpwnOptions: ["UNSET", "TIKTOK_NATIVE_PAGE"] },
    { v: "Pixel ID" },
    {
      v: "Optimization Event",
      dropdpwnOptions: [
        "",
        "ACTIVE",
        "ACTIVE_PAY",
        "ACTIVE_REGISTER",
        "ADD_TO_WISHLIST",
        "COMPLETE_TUTORIAL",
        "CREATE_GAMEROLE",
        "CREATE_GROUP",
        "IN_APP_AD_CLICK",
        "IN_APP_AD_IMPR",
        "IN_APP_CART",
        "IN_APP_DETAIL_UV",
        "JOIN_GROUP",
        "LAUNCH_APP",
        "LIVE_CLICK_PRODUCT_ACTION",
        "LIVE_STAY_TIME",
        "LOAN_COMPLETION",
        "LOGIN",
        "NEXT_DAY_OPEN",
        "RATE",
        "SALES_LEAD",
        "SEARCH",
        "SPEND_CREDITS",
        "UNLOCK_ACHIEVEMENT",
        "UPDATE_LEVEL",
        "ENGAGED_VIEW",
        "ENGAGED_VIEW_FIFTEEN",
        "LANDING_PAGE_VIEW",
        "PAGE_VISIT",
        "MESSAGE",
        "DESTINATION_VISIT",
        "AD_REVENUE_VALUE",
      ],
    },
    { v: "Placement Type", dropdpwnOptions: ["PLACEMENT_TYPE_NORMAL", "PLACEMENT_TYPE_AUTOMATIC"] },
    {
      v: "Placements",
      dropdpwnOptions: ["PLACEMENT_TIKTOK", "PLACEMENT_PANGLE", "PLACEMENT_GLOBAL_APP_BUNDLE"],
    },
    { v: "Search Result Enabled", dropdpwnOptions: ["true", "false"] },
    { v: "Comment Disabled", dropdpwnOptions: ["true", "false"] },
    { v: "Video Download Disabled", dropdpwnOptions: ["true", "false"] },
    { v: "Share Disabled", dropdpwnOptions: ["true", "false"] },
    { v: "Blocked Pangle App IDs" },
    { v: "Saved Audience IDs" },
    { v: "Auto Targeting Enabled", dropdpwnOptions: ["true", "false"] },
    { v: "Shopping Ads Retargeting Type", dropdpwnOptions: ["", "LAB1", "LAB2", "LAB3", "OFF"] },
    // {
    //   v: "Shopping Ads Retargeting Action Days",
    //   dropdpwnOptions: ["", "1", "2", "3", "7", "14", "30", "60", "90", "180"],
    // },
    // {
    //   v: "Included Custom Actions: Code",
    //   dropdpwnOptions: ["", "VIEW_PRODUCT", "ADD_TO_CART", "PURCHASE"],
    // },
    // { v: "Included Custom Actions: Days", dropdpwnOptions: ["1", "180"] },
    // {
    //   v: "Excluded Custom Actions: Code",
    //   dropdpwnOptions: ["", "VIEW_PRODUCT", "ADD_TO_CART", "PURCHASE"],
    // },
    // { v: "Excluded Custom Actions: Days", dropdpwnOptions: ["", "1", "180"] },
    { v: "Shopping Ads Retargeting Custom Audience Relation", dropdpwnOptions: ["", "OR", "AND"] },
    { v: "Location IDs" },
    { v: "Zipcode IDs" },
    {
      v: "Languages",
      dropdpwnOptions: [
        "ar",
        "as",
        "bgc",
        "bh",
        "bn",
        "cs",
        "de",
        "el",
        "en",
        "es",
        "fi",
        "fr",
        "gu",
        "he",
        "hi",
        "hu",
        "id",
        "it",
        "ja",
        "kn",
        "ko",
        "ml",
        "mr",
        "ms",
        "nl",
        "or",
        "pa",
        "pl",
        "pt",
        "raj",
        "ro",
        "ru",
        "sv",
        "ta",
        "te",
        "th",
        "tr",
        "uk",
        "vi",
        "zh-Hant",
      ],
    },
    { v: "Gender", dropdpwnOptions: ["", "GENDER_UNLIMITED", "GENDER_MALE", "GENDER_FEMALE"] },
    {
      v: "Age Groups",
      dropdpwnOptions: [
        "", 
        "AGE_13_17",
        "AGE_18_24",
        "AGE_25_34",
        "AGE_35_44",
        "AGE_45_54",
        "AGE_55_100",
      ],
    },
    { v: "Spending Power", dropdpwnOptions: ["", "ALL", "HIGH"] },
    { v: "Household Income", dropdpwnOptions: ["", "TOP5", "TOP10", "TOP10_25", "TOP25_50"] },
    { v: "Audience IDs" },
    { v: "Smart Audience Enabled", dropdpwnOptions: ["", "true", "false"] },
    { v: "Excluded Audience IDs" },
    { v: "Interest Category IDs" },
    { v: "Interest Keyword IDs" },
    { v: "Purchase Intention Keyword IDs" },
    // {
    //   v: "Actions: Action Scene",
    //   dropdpwnOptions: ["VIDEO_RELATED", "CREATOR_RELATED", "HASHTAG_RELATED"],
    // },
    // { v: "Actions: Action Period", dropdpwnOptions: ["0", "7", "15"] },
    // {
    //   v: "Actions: Video User Action",
    //   dropdpwnOptions: [
    //     "WATCHED_TO_END",
    //     "LIKED",
    //     "COMMENTED",
    //     "SHARED",
    //     "FOLLOWING",
    //     "VIEW_HOMEPAGE",
    //     "VIEW_HASHTAG",
    //   ],
    // },
    // { v: "Actions: Action Category IDs" },
    { v: "Smart Interest Behavior Enabled", dropdpwnOptions: ["", "true", "false"] },
    { v: "Included Pangle Audience Package IDs" },
    { v: "Excluded Pangle Audience Package IDs" },
    { v: "Operating Systems", dropdpwnOptions: ["", "ANDROID", "IOS"] },
    {
      v: "Min Android Version",
      dropdpwnOptions: [
        "", 
        "NONE",
        "4",
        "4.1",
        "4.2",
        "4.3",
        "4.4",
        "5",
        "5.1",
        "6",
        "7",
        "7.1",
        "8",
        "8.1",
        "9",
        "10",
        "10.1",
        "10.2",
        "10.3",
        "10.5",
        "10.6",
        "11",
        "12",
        "13",
      ],
    },
    { v: "Ios14 Targeting", dropdpwnOptions: ["", "UNSET", "IOS14_MINUS", "IOS14_PLUS", "ALL"] },
    {
      v: "Min Ios Version",
      dropdpwnOptions: [
        "", 
        "NONE",
        "8",
        "8.1",
        "8.2",
        "8.3",
        "8.4",
        "9",
        "9.1",
        "9.2",
        "9.3",
        "10.1",
        "10.2",
        "10.3",
        "11",
        "11.1",
        "11.2",
        "11.3",
        "11.4",
        "12",
        "12.1",
        "12.2",
        "12.3",
        "12.4",
        "13",
        "13.1",
        "13.2",
        "13.3",
        "13.4",
        "13.5",
        "13.6",
        "13.7",
        "14",
        "14.1",
        "14.2",
        "14.3",
        "14.4",
        "14.5",
        "14.6",
        "14.7",
        "14.8",
        "15",
        "15.1",
        "15.2",
        "15.3",
        "15.4",
        "15.5",
        "15.6",
        "16",
        "16.1",
        "16.2",
        "16.3",
        "16.4",
      ],
    },
    { v: "Device Model IDs" },
    { v: "Network Types", dropdpwnOptions: ["", "WIFI", "2G", "3G", "4G", "5G"] },
    { v: "Carrier IDs" },
    { v: "Isp IDs" },
    { v: "Device Price Ranges", dropdpwnOptions: [] },
    { v: "Targeting Expansion: Expansion Enabled", dropdpwnOptions: ["", "true", "false"] },
    {
      v: "Targeting Expansion: Expansion Types",
      dropdpwnOptions: ["", "AGE", "GENDER", "INTEREST_AND_BEHAVIOR", "CUSTOM_AUDIENCE"],
    },
    { v: "Audience Type", dropdpwnOptions: ["", "NEW_CUSTOM_AUDIENCE"] },
    { v: "Audience Rule" },
    { v: "Contextual Tag IDs" },
    {
      v: "Brand Safety Type",
      dropdpwnOptions: [
        "",
        "NO_BRAND_SAFETY",
        "EXPANDED_INVENTORY",
        "STANDARD_INVENTORY",
        "LIMITED_INVENTORY",
        "THIRD_PARTY",
      ],
    },
    { v: "Brand Safety Partner", dropdpwnOptions: ["", "IAS", "OPEN_SLATE"] },
    { v: "Category Exclusion IDs" },
    { v: "Vertical Sensitivity Id" },
    {
      v: "Budget Mode",
      dropdpwnOptions: ["BUDGET_MODE_TOTAL", "BUDGET_MODE_DYNAMIC_DAILY_BUDGET", "BUDGET_MODE_DAY"],
    },
    { v: "Budget" },
    { v: "Schedule Type", dropdpwnOptions: ["SCHEDULE_START_END", "SCHEDULE_FROM_NOW"] },
    { v: "Schedule Start Time" },
    { v: "Schedule End Time" },
    { v: "Dayparting" },
    {
      v: "Optimization Goal",
      dropdpwnOptions: [
        "REACH",
        "VIDEO_VIEWS",
        "TRAFFIC",
        "WEB_CONVERSIONS",
        "APP_PROMOTION",
        "LEAD_GENERATION",
        "ENGAGEMENT",
        "PRODUCT_SALES",
      ],
    },
    { v: "Frequency" },
    { v: "Frequency Schedule" },
    {
      v: "Secondary Optimization Event",
      dropdpwnOptions: [
        "ACTIVE_PAY",
        "ACTIVE_REGISTER",
        "IN_APP_CART",
        "NEXT_DAY_OPEN",
        "PURCHASE_ROI",
        "SUBSCRIBE",
        "UPDATE_LEVEL",
        "DAY7_RETENTION",
        "PREFERRED_LEAD",
      ],
    },
    { v: "Bid Type", dropdpwnOptions: ["BID_TYPE_CUSTOM", "BID_TYPE_NO_BID"] },
    { v: "Bid Price" },
    { v: "Conversion Bid Price" },
    { v: "Deep Bid Type", dropdpwnOptions: ["", "VO_MIN_ROAS", "VO_HIGHEST_VALUE"] },
    { v: "Roas Bid" },
    { v: "Vbo Window", dropdpwnOptions: ["", "SEVEN_DAYS", "ZERO_DAY"] },
    { v: "Bid Display Mode", dropdpwnOptions: ["", "CPV"] },
    { v: "Next Day Retention" },
    {
      v: "Click Attribution Window",
      dropdpwnOptions: ["", "UNSET", "ONE_DAY", "SEVEN_DAYS", "FOURTEEN_DAYS", "TWENTY_EIGHT_DAYS"],
    },
    { v: "Engaged View Attribution Window", dropdpwnOptions: ["", "ONE_DAY", "SEVEN_DAYS"] },
    { v: "View Attribution Window", dropdpwnOptions: ["", "OFF", "ONE_DAY", "SEVEN_DAYS"] },
    { v: "Attribution Event Count", dropdpwnOptions: ["", "UNSET", "EVERY", "ONCE"] },
    { v: "Billing Event", dropdpwnOptions: ["CPC", "CPM", "CPV", "OCPC", "GD", "OCPM"] },
    { v: "Pacing", dropdpwnOptions: ["PACING_MODE_SMOOTH", "PACING_MODE_FAST"] },
    { v: "Statistic Type", dropdpwnOptions: ["EVERYTIME", "NONE"] },
    { v: "Is Hfss", dropdpwnOptions: ["", "true", "false"] },
    { v: "Operation Status", dropdpwnOptions: ["DISABLE", "ENABLE"] },
    { v: "Creative Material Mode", dropdpwnOptions: ["", "CUSTOM", "DYNAMIC"] },
    { v: "Adgroup App Profile Page State", dropdpwnOptions: ["", "ON", "OFF"] },
  ],
  Insight: [
    { v: "Package Config Defaults	" },
    { v: "Placement Dimension" },
    { v: "Package Compatibilty" },
    { v: "Package Start Date" },
    { v: "Package End Date" },
    { v: "Hard Cutoff" },
    { v: "Geo targeting" },
    { v: "Package Startegy" },
    { v: "Content Category" },
    { v: "DV Wrapping" },
  ],
  "Autotrader": [
    { v: "Package Config Defaults	" },
    { v: "Placement Dimension" },
    { v: "Package Compatibilty" },
    { v: "Package Start Date" },
    { v: "Package End Date" },
    { v: "Hard Cutoff" },
    { v: "Geo targeting" },
    { v: "Package Startegy" },
    { v: "Content Category" },
    { v: "DV Wrapping" },
  ],
  "Royal LePage": [
    { v: "Package Config Defaults	" },
    { v: "Placement Dimension" },
    { v: "Package Compatibilty" },
    { v: "Package Start Date" },
    { v: "Package End Date" },
    { v: "Hard Cutoff" },
    { v: "Geo targeting" },
    { v: "Package Startegy" },
    { v: "Content Category" },
    { v: "DV Wrapping" },
  ]
};

export const config2 = {
  DV360: [
    { v: "LI Config Defaults" },
    { v: "Line Item Start Date (MM/DD/YYYY)*" },
    { v: "Line Item End Date (MM/DD/YYYY)*" },
    {
      v: "Line Item Type*",
      dropdpwnOptions: [
        "LINE_ITEM_TYPE_DISPLAY_DEFAULT",
        "LINE_ITEM_TYPE_DISPLAY_MOBILE_APP_INSTALL",
        "LINE_ITEM_TYPE_VIDEO_DEFAULT",
        "LINE_ITEM_TYPE_VIDEO_MOBILE_APP_INSTALL",
        "LINE_ITEM_TYPE_DISPLAY_MOBILE_APP_INVENTORY",
        "LINE_ITEM_TYPE_VIDEO_MOBILE_APP_INVENTORY",
        "LINE_ITEM_TYPE_AUDIO_DEFAULT",
        "LINE_ITEM_TYPE_VIDEO_OVER_THE_TOP",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_ACTION",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_NON_SKIPPABLE",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_VIDEO_SEQUENCE",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_AUDIO",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_REACH",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_SIMPLE",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_NON_SKIPPABLE_OVER_THE_TOP",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_REACH_OVER_THE_TOP",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_SIMPLE_OVER_THE_TOP",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_TARGET_FREQUENCY",
        "LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_VIEW",
        "LINE_ITEM_TYPE_DISPLAY_OUT_OF_HOME",
        "LINE_ITEM_TYPE_VIDEO_OUT_OF_HOME",
      ],
    },
    {
      v: "costType*",
      dropdpwnOptions: [
        "PARTNER_COST_TYPE_ADLOOX",
        "PARTNER_COST_TYPE_ADLOOX_PREBID",
        "PARTNER_COST_TYPE_ADSAFE",
        "PARTNER_COST_TYPE_ADXPOSE",
        "PARTNER_COST_TYPE_AGGREGATE_KNOWLEDGE",
        "PARTNER_COST_TYPE_AGENCY_TRADING_DESK",
        "PARTNER_COST_TYPE_DV360_FEE",
        "PARTNER_COST_TYPE_COMSCORE_VCE",
        "PARTNER_COST_TYPE_DATA_MANAGEMENT_PLATFORM",
        "PARTNER_COST_TYPE_DEFAULT",
        "PARTNER_COST_TYPE_DOUBLE_VERIFY",
        "PARTNER_COST_TYPE_DOUBLE_VERIFY_PREBID",
        "PARTNER_COST_TYPE_EVIDON",
        "PARTNER_COST_TYPE_INTEGRAL_AD_SCIENCE_VIDEO",
        "PARTNER_COST_TYPE_INTEGRAL_AD_SCIENCE_PREBID",
        "PARTNER_COST_TYPE_MEDIA_COST_DATA",
        "PARTNER_COST_TYPE_MOAT_VIDEO",
        "PARTNER_COST_TYPE_NIELSEN_DAR",
        "PARTNER_COST_TYPE_SHOP_LOCAL",
        "PARTNER_COST_TYPE_TERACENT",
        "PARTNER_COST_TYPE_THIRD_PARTY_AD_SERVER",
        "PARTNER_COST_TYPE_VIZU",
        "PARTNER_COST_TYPE_ADLINGO_FEE",
      ],
    },
    {
      v: "feeType*",
      dropdpwnOptions: ["PARTNER_COST_FEE_TYPE_CPM_FEE", "PARTNER_COST_FEE_TYPE_MEDIA_FEE"],
    },
    {
      v: "invoiceType",
      dropdpwnOptions: ["PARTNER_COST_INVOICE_TYPE_DV360", "PARTNER_COST_INVOICE_TYPE_PARTNER"],
    },
    {
      v: "flightDateType*",
      dropdpwnOptions: [
        "LINE_ITEM_FLIGHT_DATE_TYPE_INHERITED",
        "LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM",
      ],
    },
    { v: "flight startDate" },
    { v: "flight endDate" },
    {
      v: "budgetAllocationType*",
      dropdpwnOptions: [
        "LINE_ITEM_BUDGET_ALLOCATION_TYPE_AUTOMATIC",
        "LINE_ITEM_BUDGET_ALLOCATION_TYPE_FIXED",
        "LINE_ITEM_BUDGET_ALLOCATION_TYPE_UNLIMITED",
      ],
    },
    { v: "LineItemBudget maxAmount" },
    {
      v: "PacingPeriod*",
      dropdpwnOptions: ["PACING_PERIOD_DAILY", "PACING_PERIOD_FLIGHT"],
    },
    {
      v: "PacingType*",
      dropdpwnOptions: ["PACING_TYPE_EVEN", "PACING_TYPE_ASAP", "PACING_TYPE_AHEAD"],
    },
    { v: "DailyMaxMicros*" },
    { v: "DailyMaxImpressions" },

    {
      v: "FrequencyCap Unlimited*",
      dropdpwnOptions: ["TRUE", "FALSE"],
    },
    {
      v: "FrequencyCap TimeUnit",
      dropdpwnOptions: [
        "TIME_UNIT_LIFETIME",
        "TIME_UNIT_MONTHS",
        "TIME_UNIT_WEEKS",
        "TIME_UNIT_DAYS",
        "TIME_UNIT_HOURS",
        "TIME_UNIT_MINUTES",
      ],
    },
    { v: "FrequencyCap TimeUnit Count" },
    { v: "FrequencyCap MaxImpressions" },
    {
      v: "PartnerRevenueModel markupType*",
      dropdpwnOptions: [
        "PARTNER_REVENUE_MODEL_MARKUP_TYPE_CPM",
        "PARTNER_REVENUE_MODEL_MARKUP_TYPE_TOTAL_MEDIA_COST_MARKUP",
      ],
    },
    { v: "PartnerRevenueModel markupAmount*" },
    { v: "FixedBidStrategy bidAmountMicros*" },
    {
      v: "enableOptimizedTargeting",
      dropdpwnOptions: ["FALSE", "TRUE"],
    },
    {
      v: "excludeNewExchanges",
      dropdpwnOptions: ["FALSE", "TRUE"],
    },
  ],
  Quantcast: [],
  TradeDesk: [],
  Meta: [],
  Amazon: [],
  Verizon: [],
  TikTok: [],
  LinkedIn: [
    { v: "Campaign Config Defaults" },
    { v: "audienceExpansionEnabled*", dropdpwnOptions: ["TRUE", "FALSE"] },
    { v: "offsiteDeliveryEnabled*", dropdpwnOptions: ["TRUE", "FALSE"] },
    { v: "costType*", dropdpwnOptions: ["CPM", "CPC", "CPV"] },
    {
      v: "objectiveType*",
      dropdpwnOptions: [
        "BRAND_AWARENESS",
        "ENGAGEMENT",
        "JOB_APPLICANT",
        "LEAD_GENERATION",
        "WEBSITE_CONVERSION",
        "WEBSITE_VISIT",
        "VIDEO_VIEW",
      ],
    },
    { v: "format*", dropdpwnOptions: ["TEXT_AD", "SINGLE_IMAGE", "SINGLE_VIDEO"] },
    { v: "creativeSelection*", dropdpwnOptions: ["ROUND_ROBIN", "OPTIMIZED"] },
    { v: "runSchedule start*" },
    { v: "runSchedule end" },
    { v: "targetingCriteria*" },
    { v: "unitCost amount*" },
    { v: "unitCost currencyCode*" },
    { v: "dailyBudget amount" },
    { v: "dailyBudget currencyCode" },
    { v: "status*", dropdpwnOptions: ["DRAFT", "ACTIVE", "PAUSED", "ARCHIVED"] },
  ],
  Reddit: [
    { v: "Ads and Posts Config Defaults" },
    { v: "name*" },
    {
      v: "campaign_objective_type",
      dropdpwnOptions: [
        "CLICKS",
        "APP_INSTALLS",
        "CATALOG_SALES",
        "CONVERSIONS",
        "IMPRESSIONS",
        "LEAD_GENERATION",
        "VIDEO_VIEWABLE_IMPRESSIONS",
      ],
    },
    { v: "click_url" },
    { v: "configured_status*", dropdpwnOptions: ["ACTIVE", "ARCHIVED", "DELETED", "PAUSED"] },
    { v: "event_trackers type", dropdpwnOptions: ["CLICK", "VIEW"] },
    { v: "event_trackers url" },
    { v: "preview_expiry" },
    { v: "allow_comments", dropdpwnOptions: [false, true] },
    { v: "body*" },
    {
      v: "content call_to_action*",
      dropdpwnOptions: [
        "Apply Now",
        "Contact Us",
        "Download",
        "Get a Quote",
        "Get Showtimes",
        "Install",
        "Learn More",
        "Order Now",
        "Play Now",
        "Pre-order Now",
        "See Menu",
        "Shop Now",
        "Sign Up",
        "View More",
        "Watch Now",
      ],
    },
    { v: "content caption*" },
    { v: "content destination_url*" },
    { v: "content display_ur*l" },
    { v: "content media_url*" },
    { v: "headline*" },
    { v: "is_richtext*", dropdpwnOptions: [true, false] },
    { v: "thumbnail_url" },
    { v: "type*", dropdpwnOptions: ["CAROUSEL", "IMAGE", "TEXT", "VIDEO"] },
  ],
  "Apex Exchange": [
    { v: "Ad Set Config Defaults	" },
    { v: "Ad Set Start Date" },
    { v: "Ad Set End Date" },
    { v: "Line Frequency Cap" },
    { v: "Creative Type" },
    { v: "Creative Rotation" },
    { v: "Click-Through URL" },
  ],
  Insight: [
    { v: "Ad Set Config Defaults	" },
    { v: "Ad Set Start Date" },
    { v: "Ad Set End Date" },
    { v: "Line Frequency Cap" },
    { v: "Creative Type" },
    { v: "Creative Rotation" },
    { v: "Click-Through URL" },
  ],
  Autotrader: [
    { v: "Ad Set Config Defaults	" },
    { v: "Ad Set Start Date" },
    { v: "Ad Set End Date" },
    { v: "Line Frequency Cap" },
    { v: "Creative Type" },
    { v: "Creative Rotation" },
    { v: "Click-Through URL" },
  ],
  "Royal LePage": [
    { v: "Ad Set Config Defaults	" },
    { v: "Ad Set Start Date" },
    { v: "Ad Set End Date" },
    { v: "Line Frequency Cap" },
    { v: "Creative Type" },
    { v: "Creative Rotation" },
    { v: "Click-Through URL" },
  ]
};

export const config3 = {
  DV360: [],
  LinkedIn: [
    { v: "Creative Config Defaults" },
    { v: "title*" },
    { v: "landingPage/source*" },
    { v: "altTextForMedia" },
    { v: "description*" },
    {
      v: "leadgenCallToAction*",
      dropdpwnOptions: [
        "APPLY",
        "DOWNLOAD",
        "VIEW_QUOTE",
        "LEARN_MORE",
        "SIGN_UP",
        "SUBSCRIBE",
        "REGISTER",
        "REQUEST_DEMO",
        "JOIN",
        "ATTEND",
        "UNLOCK_FULL_DOCUMENT",
      ],
    },
    { v: "intendedStatus*", dropdpwnOptions: ["DRAFT", "ACTIVE", "PAUSED", "ARCHIVED"] },
  ]
};
