import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RecipeSummaryData } from "components/Common/staticData";
import React, { useState, useEffect } from "react";
import { Dropzone, FileMosaic } from "@files-ui/react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch } from "react-redux";
import setLoading from "features/helper";
import { AppDispatch } from "app/store";
import { deleteFiles, downloadFiles, fetchFiles } from "features/recipe/recipeSlice";

const DropzoneUI = ({
  label,
  type = "text",
  fieldData,
  placeholder = "",
  filesToUpload,
  setFilesToUpload,
  recipeId,
  recipe
}: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const [error, setError] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  const [filesInDropzone, setFilesInDropzone] = useState([]);

  const selectedFile = fieldData?.fieldValues[fieldData?.index][fieldData?.fieldName]
    ? fieldData?.fieldValues[fieldData?.index][fieldData?.fieldName]
    : "";

  useEffect(() => {
    setLoading(true);
    dispatch(fetchFiles({ filedName: fieldData.fieldName, recipeId: recipeId })).then((res) => {
      setDropzoneFiles(res.payload.items);
      setLoading(false);
    });
  }, [recipe]);

  const setFiles = async (acceptedFiles) => {
    let updatedAcceptedFiles = acceptedFiles.map(item => {
      let timestamp = new Date().toISOString().replace(/[^\d]/g, '').slice(0, 8) + "_" + new Date().toISOString().slice(11, 19).replace(/:/g, '');
      item.name = item.name.replace(/\.[^/.]+$/, "") + "_" + timestamp + item.name.slice(item.name.lastIndexOf('.'));
      const newFile = new File([item.file], item.name);
      item.file = newFile;
      return item;
    });

    fieldData.fieldValues[fieldData.index][fieldData.fieldName] = updatedAcceptedFiles.length > 0 
    ? updatedAcceptedFiles[updatedAcceptedFiles.length - 1].name 
    : "";
    fieldData?.setFieldValues(fieldData?.fieldValues);
    filesToUpload[fieldData.fieldName] = updatedAcceptedFiles;
  
    setFilesToUpload(filesToUpload);
    setFilesInDropzone(updatedAcceptedFiles);
    return;
  };

  return (
    <div className="my-4">
      <div className="flex items-center text-sm my-1">
        <p className="font-semibold mr-2 min-w-max">{label}</p>
        <button
          className="cursor-pointer mr-2"
          onClick={() => setShowDetails((prev) => !prev)}
          type="button"
        >
          <InfoOutlinedIcon style={{ fontSize: "1rem" }} />
        </button>
        {showDetails ? (
          <p className="font-medium text-[#808080]">{fieldData?.description}</p>
        ) : null}
        {error && <div className="text-red">{RecipeSummaryData.ErrorMsg}</div>}
      </div>
      <div className="flex flex-row">
        <Dropzone
          style={{ height: "30%", flexBasis: "25%" }}
          onChange={setFiles}
          value={filesInDropzone}
        >
          {filesInDropzone?.map((file, i) =>
            <FileMosaic key={i} {...file} preview />
          )}
        </Dropzone>
        <div className="flex flex-col justify-center">
          {dropzoneFiles?.map((file, i) => (
            <div className="flex flex-row justify-between items-center mb-1 ml-10">
              <div className="flex flex-row">
              <input
                className="mr-3 cursor-pointer w-5 h-5 border-2 border-gray-300 rounded-sm"
                type="checkbox"
                checked={file.name.split("/").pop() === selectedFile}
                onChange={() => {
                  fieldData.fieldValues[fieldData.index][fieldData.fieldName] = file.name.split("/").pop();
                  fieldData?.setFieldValues(fieldData?.fieldValues);
                }}
              />

              <div
                className={`hover:underline hover:cursor-pointer mr-2`}
                onClick={() => {
                  dispatch(downloadFiles(file.name)).then(async (res) => {
                    window.location.href = res.payload.downloadUrl;
                  })
                }}
              >
                {file.name.split("/").pop()}
              </div>
              </div>
              <div>
                <DeleteOutlineIcon
                  className="text-primary cursor-pointer hover:text-red"
                  style={{ fontSize: "1.2rem" }}
                  onClick={() => {
                    setLoading(true);
                    dispatch(deleteFiles(file.name)).then(() => {
                      dispatch(
                        fetchFiles({ filedName: fieldData.fieldName, recipeId: recipeId })
                      ).then((res) => {
                        setDropzoneFiles(res.payload.items);
                        setLoading(false);
                      });
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropzoneUI;
