import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";

const Switch = ({ fieldData, label }: any) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="my-4">
      <div className="flex items-center text-sm my-1">
        <p className="font-semibold mr-2 min-w-max">{label}</p>
        <button
          className="cursor-pointer mr-2"
          type="button"
          onClick={() => setShowDetails(prev=>!prev)}
        >
          <InfoOutlinedIcon style={{ fontSize: "1rem" }} />
        </button>
        {showDetails ? (
          <p className="font-medium text-[#808080]">{fieldData.description}</p>
        ) : null}
      </div>
      <div className="flex items-center justify-start gap-x-2 ml-8">
        <span className="text-sm">User</span>
        <label className="inline-flex relative items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={
              fieldData?.fieldValues[fieldData.index][fieldData.fieldName] ===
              "service"
            }
            onChange={(e) => {
              fieldData.fieldValues[fieldData.index][fieldData.fieldName] ===
              "service"
                ? (fieldData.fieldValues[fieldData.index][fieldData.fieldName] =
                    "user")
                : (fieldData.fieldValues[fieldData.index][fieldData.fieldName] =
                    "service");
              fieldData.setFieldValues(fieldData.fieldValues);
            }}
          />
          <div
            className={`w-6 h-3.5 border-2 rounded-full left-0
              peer-checked:after:translate-x-full after:absolute after:top-1 after:left-[5px]
              after:bg-primary after:rounded-full after:h-1.5 after:w-1.5 after:transition-all`}
          />
        </label>
        <span className="text-sm">Service</span>
      </div>
    </div>
  );
};

export default Switch;
