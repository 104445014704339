import React, { useEffect, useState } from "react";
import PageHeader from "components/PageHeader/PageHeader";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArticleIcon from "@mui/icons-material/Article";
import RefreshIcon from "@mui/icons-material/Refresh";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import _ from "lodash";
import { getSelectedRecipe, getStatus, pauseRecipe, runRecipe } from "features/recipe/recipeSlice";

import setLoading from "features/helper";
import { checkRolePermission } from "components/Common/config";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const RecipeStatus = ({
  recipeLog,
  recipeId,
  setRecipeLog,
  edit,
  permissions_enabled,
  setShowLogsModal,
  setLogText,
  currentUser,
  setShowCopyModal,
  setShowDeleteModal,
  permissionsEnabled,
  CreateRecipe
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const roleResources = currentUser.role.resources;
  const canDuplicateRecipe = roleResources.Create
    ? roleResources.Create.includes("Duplicate")
    : false;
  const recipeDeletePermissions = roleResources["Delete Recipes"];
  const runRole = currentUser.role.resources["Run ad hoc recipes"];
  let canDeleteRecipe = checkRolePermission(
    recipeDeletePermissions,
    recipeLog?.group,
    currentUser.userID === recipeLog?.user_id,
    currentUser
  );
  let canRun = checkRolePermission(
    runRole,
    recipeLog?.group,
    currentUser.userID === recipeLog?.user_id,
    currentUser
  );

  let progressColor;
  if (recipeLog?.status === "FINISHED") {
    progressColor = "#0177FB";
  } else if (recipeLog?.status === "ERROR") {
    progressColor = "#DA2801";
  } else if (recipeLog?.status === "STOPPED") {
    progressColor = "#FB5B01";
  } else {
    progressColor = "#0CC5FF";
  }

  const recipePause = () => {
    setLoading(true);
    dispatch(pauseRecipe(recipeId)).then(() => {
      dispatch(getSelectedRecipe(recipeId)).then((res) => {
        var tempArray = _.cloneDeep(recipeLog);
        if (tempArray) {
          tempArray.percent = res.payload.percent;
          tempArray.active = res.payload.active;
          tempArray.ago = res.payload.ago;
          tempArray.status = res.payload.status;
          setRecipeLog(tempArray);
        } 
      });
      setLoading(false);
    });
  }

  return (
    <>
      <PageHeader title={"Recipe Status"} />
      <div className="flex flex-col items-center justify-center sticky top-[10rem] rounded-3xl border-[15px] border-[#BCEBEF] p-5">
        <div className="my-8">
          <button
            onClick={() => {
              setLoading(true);
              dispatch(getSelectedRecipe(recipeId)).then((res) => {
                var tempArray = _.cloneDeep(recipeLog);
                if (tempArray) {
                  tempArray.percent = res.payload.percent;
                  tempArray.active = res.payload.active;
                  tempArray.ago = res.payload.ago;
                  tempArray.status = res.payload.status;
                  setRecipeLog(tempArray);
                }
                setLoading(false);
              });
            }}
          >
            <RefreshIcon className="mr-3 text-primary" style={{ fontSize: "1.9rem" }} />
          </button>
          <button
            onClick={() => {
              setLoading(true);
              dispatch(getStatus(recipeId)).then((res) => {
                setLoading(false);
                setLogText(res.payload.tasks);
                setShowLogsModal(true);
              });
            }}
          >
            <ArticleIcon className="mr-3 text-primary" style={{ fontSize: "1.9rem" }} />
          </button>
          <button
            onClick={() => {
              let ingredientTitleArr = [];
              for (let index = 0; index < recipeLog?.ingredients.length; index++) {
                ingredientTitleArr.push(recipeLog?.ingredients[index].title);
              }

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "duplicate_recipe_icon",
                recipe_id: recipeLog?.id,
                recipe_name: recipeLog?.name,
                group_name: recipeLog?.group,
                ingredient: ingredientTitleArr,
                recipe_type: recipeLog?.manual ? "manual" : "schedule",
                user_id: currentUser.userID,
                tenant_id: currentUser.tenant,
              });
              setShowCopyModal(true);
            }}
            disabled={!canDuplicateRecipe}
          >
            <FileCopyOutlinedIcon
              className={`mr-3 ${!canDuplicateRecipe ? "text-slate-300" : "text-primary"}`}
              style={{ fontSize: "1.9rem" }}
            />
          </button>
          <button
            onClick={() => {
              let ingredientTitleArr = [];
              for (let index = 0; index < recipeLog?.ingredients.length; index++) {
                ingredientTitleArr.push(recipeLog?.ingredients[index].title);
              }
              setShowDeleteModal(true);
            }}
            disabled={!canDeleteRecipe}
          >
            <DeleteOutlineIcon
              className={`${!canDeleteRecipe ? "text-slate-300" : "text-primary"}`}
              style={{ fontSize: "1.9rem" }}
            />
          </button>
        </div>
        <div className="bg-[#F5F6F8] flex flex-col items-center h-24 p-3 pb-10 w-[90%] rounded-2xl mb-5">
          <p className="mb-3 font-normal text-primary">Last Run</p>
          <p className="font-semibold text-center text-primary text-lg mb-10">
            {recipeLog?.ago && recipeLog?.ago !== "None"
              ? recipeLog?.ago
              : // ? moment(lastRun, "YYYY-MM-DD").format("MMM DD, YYYY").toUpperCase()
                "New Recipe"}
          </p>
        </div>
        <div className="bg-[#F5F6F8] flex flex-col items-center h-32 p-3 w-[90%] rounded-2xl mb-5">
          <p className="mb-3 font-normal text-primary">{recipeLog?.status?.toUpperCase()}</p>
          <CircularProgressbar
            value={recipeLog?.percent ? recipeLog?.percent : 0}
            text={`${recipeLog?.percent ? recipeLog?.percent : 0}%`}
            styles={buildStyles({
              textSize: "1.5rem",
              textColor: "#40506A",
              pathColor: progressColor,
            })}
          />
        </div>
        <div className="basis-[48%] bg-[#F5F6F8] flex flex-col items-center h-36 w-[90%] p-3 rounded-2xl">
          <p className="mb-2 font-normal text-primary">Run Time</p>
          <p className="font-normal text-primary text-4xl mb-1">
            {recipeLog?.runTime ? recipeLog?.runTime : "-"}
          </p>
          <div className="flex flex-col items-center">
            <div>
              {recipeLog?.active ? (
                <>
                  <button
                    disabled={!canRun || !permissions_enabled}
                    onClick={() => CreateRecipe(true)}
                  >
                    <PlayCircleIcon
                      className={`${
                        !canRun || !permissions_enabled
                          ? "text-slate-400"
                          : "text-primary cursor-pointer"
                      } mr-1`}
                      style={{ fontSize: "1.75rem" }}
                    />
                  </button>
                  <button
                    disabled={!canRun || !permissions_enabled}
                    onClick={() => recipePause()}
                  >
                    <StopCircleOutlinedIcon
                      className={`${
                        !canRun || !permissions_enabled
                          ? "text-slate-400"
                          : "text-primary cursor-pointer"
                      }`}
                      style={{ fontSize: "1.75rem" }}
                    />
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={!canRun || !permissions_enabled}
                    onClick={() => CreateRecipe(true)}
                  >
                    <PlayCircleOutlineIcon
                      className={`${
                        !canRun || !permissions_enabled
                          ? "text-slate-400"
                          : "text-primary cursor-pointer"
                      } mr-1`}
                      style={{ fontSize: "1.75rem" }}
                    />
                  </button>
                  <button
                    disabled={!canRun || !permissions_enabled}
                    onClick={() => recipePause()}
                  >
                    <StopCircleIcon
                      className={`${
                        !canRun || !permissions_enabled
                          ? "text-slate-400"
                          : "text-primary cursor-pointer"
                      }`}
                      style={{ fontSize: "1.75rem" }}
                    />
                  </button>
                </>
              )}
            </div>
            {!permissions_enabled ? (
              <p className="text-red text-center text-sm">Enable Permissions to Run Recipe</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipeStatus;
