import React from "react";
import Button from "@mui/material/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const DateSelectionButton = ({ text, onClick }: any) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`bg-beige text-primary rounded-[9px] px-3.5 py-2 whitespace-nowrap cursor-pointer text-xs focus:bg-darkBeige focus:text-white hover:bg-[#ACACAC] hover:text-white`}
    >
      {text}
    </button>
  );
};

export const ClearButton = ({ text, onClick }: any) => {
  return (
    <div
      onClick={onClick}
      className={`bg-beige text-primary rounded-[9px] px-3.5 py-2 whitespace-nowrap cursor-pointer text-xs hover:bg-darkBeige hover:text-white`}
    >
      {text}
    </div>
  );
};

export const ApplyButton = ({ text, onClick }: any) => {
  return (
    <div
      onClick={onClick}
      className={`bg-blue text-white rounded-[9px] px-3.5 py-2 whitespace-nowrap cursor-pointer text-xs`}
    >
      {text}
    </div>
  );
};

export const HeaderButton = ({ text, onClick, disabled }: any) => {
  return (
    <div className={"mx-10"}>
      <Button
        variant="contained"
        size="large"
        className="flex justify-between"
        style={{
          padding: "6% 12%",
          backgroundColor: disabled ? "#CBD5E1" : "#0177FB",
        }}
        disableElevation
        onClick={onClick}
        disabled={disabled}
      >
        <AddIcon style={{ marginRight: "3%" }} />
        <div className={"font-semibold normal-case whitespace-nowrap"}>
          {text}
        </div>
      </Button>
    </div>
  );
};

export const DafaultButton = ({
  text,
  color,
  outlined,
  startIcon,
  endIcon,
  textColor = "primary",
  onClick,
  width = "auto",
  margin,
  padding,
  radius = 2,
  disabled,
  type="button",
}: any) => {
  const theme = createTheme({
    palette: {
      primary: { main: "#40506A" },
      success: { main: "#0EB200", contrastText: `${textColor} ` },
      secondary: { main: "#B2B2B2", contrastText: `${textColor} ` },
      info: { main: "#0177FB", contrastText: `${textColor} ` },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        style={{
          width: `${width}`,
          margin: `${margin}`,
          padding: `${padding}`,
        }}
        disabled={disabled}
        type={type}
        variant={outlined ? "outlined" : "contained"}
        startIcon={startIcon || null}
        endIcon={endIcon || null}
        color={color}
        onClick={onClick}
        sx={{ border: 1, borderRadius: radius }}
        disableElevation
      >
        <div
          className={`text-${textColor} font-semibold normal-case whitespace-nowrap`}
        >
          {text}
        </div>
      </Button>
    </ThemeProvider>
  );
};
