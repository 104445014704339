import ApiOperations from "../ApiOperations";
import config from "components/Common/config";

const listRecipes = async (tenant_id) => {
  let x = await ApiOperations.get(
    `${config.apiGateway.recipeURL}/api/v1/recipes/list/${tenant_id}`,
    (status, data) => {
      if (status > 400) {
        data = [];
      }
      return data;
    }
  );

  return x;
};

const CreateScheduledRecipe = async (payload: any) => {
  let x = await ApiOperations.post(
    `${config.apiGateway.recipeURL}/api/v1/recipes/create_scheduled_recipe/`,
    payload,
    (status, data) => {
      return { status, data };
    }
  );

  return x;
};

const CreateManualRecipe = async (filesToUpload: any, payload: any) => {
  let x = await ApiOperations.post(
    `${config.apiGateway.recipeURL}/api/v1/recipes/create_manual_recipe/`,
    payload,
    (status, data) => {
      return { status, data };
    }
  );

  const id = x.data.recipe_id;

  if (filesToUpload) {
    const filesToUploadArray = [];
    if (filesToUpload["assets"]) {
      filesToUploadArray.push(filesToUpload["assets"]);
    }
    if (filesToUpload["placementSheet"]) {
      filesToUploadArray.push(filesToUpload["placementSheet"]);
    }
    if (filesToUpload["finalSheet"]) {
      filesToUploadArray.push(filesToUpload["finalSheet"]);
    }
    if (filesToUpload["configSheet"]) {
      filesToUploadArray.push(filesToUpload["configSheet"]);
    }

    const finalPayload = new FormData();
    filesToUploadArray.forEach((file) => {
      finalPayload.append("files", file);
    });
    await ApiOperations.post(
      `${config.apiGateway.recipeURL}/api/v1/recipes/upload_assets_to_gcs/` + id,
      finalPayload,
      (status, data) => {}
    );
  }

  return x;
};

const editScheduledRecipe = async (payload: any, id: any) => {
  let x = await ApiOperations.post(
    `${config.apiGateway.recipeURL}/api/v1/recipes/edit_scheduled_recipe/` + id,
    payload,
    (status, data) => {
      return { status, data };
    }
  );

  return x;
};

const editManualRecipe = async (filesToUpload, payload: any, id: any) => {
  let x = await ApiOperations.post(
    `${config.apiGateway.recipeURL}/api/v1/recipes/edit_manual_recipe/` + id,
    payload,
    (status, data) => {
      return { status, data };
    }
  );
  
  if (filesToUpload) {
    const filesToUploadArray = [];
    if (filesToUpload["assets"]) {
      filesToUploadArray.push(filesToUpload["assets"]);
    }
    if (filesToUpload["placementSheet"]) {
      filesToUploadArray.push(filesToUpload["placementSheet"]);
    }
    if (filesToUpload["finalSheet"]) {
      filesToUploadArray.push(filesToUpload["finalSheet"]);
    }
    if (filesToUpload["configSheet"]) {
      filesToUploadArray.push(filesToUpload["configSheet"]);
    }

    const finalPayload = new FormData();
    filesToUploadArray.forEach((file) => {
      finalPayload.append("files", file);
    });
    await ApiOperations.post(
      `${config.apiGateway.recipeURL}/api/v1/recipes/upload_assets_to_gcs/` + id,
      finalPayload,
      (status, data) => {}
    );
  }
  return x;
};

const getRecipe = async (id: any) => {
  let response = ApiOperations.get(
    `${config.apiGateway.recipeURL}/api/v1/recipes/get_recipe_by_id/` + id,
    (status, data) => {
      let finalData: any = {};
      finalData.name = data.recipe.recipe_name;
      finalData.description = data.recipe.description;
      finalData.groups = data.recipe.group;
      finalData.manual = data.recipe.manual;
      finalData.permissions_enabled = data.recipe.permissions_enabled;
      finalData.schedule_type = data.recipe.schedule_type;
      finalData.start_date = data.recipe.start_date;
      finalData.active = data.recipe.active;
      finalData.week = data.recipe.week;
      finalData.hour = data.recipe.hour;
      finalData.rt_tool = data.recipe.rt_tool;
      finalData.rt_tool_mapped = data.recipe.rt_tool_mapped;
      finalData.order = [];
      finalData.solutions = [];
      finalData.ingredientId = [];

      data.recipe.tasks.forEach((element: any, index: any) => {
        let solution_tag = element.tag;
        finalData.order.push(solution_tag);
        finalData.ingredientId.push({
          [solution_tag]: {
            fields: element.variables,
            title: element.title,
            description: element.description,
            instructions: element.instructions,
          },
        });
        let obj = element.values;
        obj["form_prefix"] = element.tag + "_" + index.toString();
        finalData.solutions.push(obj);
      });
      return finalData;
    }
  );
  return response;
};

const copyRecipe = async (id: any, payload: any) => {
  ApiOperations.post(
    `${config.apiGateway.recipeURL}/api/v1/recipes/copy_recipe/` + id,
    payload,
    (status, data) => {}
  );
  return;
};

const deleteRecipe = async (id: any) => {
  await ApiOperations.delete(
    `${config.apiGateway.recipeURL}/api/v1/recipes/delete/` + id,
    (status, data) => {}
  );

  return;
};

const checkPermission = async (id: any) => {
  let resp = await ApiOperations.get(
    `${config.apiGateway.recipeURL}/api/v1/recipes/checkPermissionsRequired/` + id,
    (status, data) => {
      return data.permissionsRequired;
    }
  );

  return resp;
};

const viewIngredientFields = async (id: any, type: any) => {
  let finalIngredientData = [];
  let solutions = [];
  for (let i = 0; i < id.length; i++) {
    let obj: any = {};
    obj[id[i]] = {};
    let fields = await ApiOperations.get(
      `${config.apiGateway.ingredientURL}/api/v1/ingredients/getFields/${id[i]}`,
      (status, data) => {
        return data;
      }
    );
    let details = await ApiOperations.get(
      `${config.apiGateway.ingredientURL}/api/v1/ingredients/${id[i]}`,
      (status, data) => {
        return data;
      }
    );
    obj[id[i]]["fields"] = fields;
    obj[id[i]]["title"] = details["script"]["title"];
    obj[id[i]]["instructions"] = details["script"]["instructions"];
    finalIngredientData.push(obj);

    let solutionObject = { form_prefix: id[i] + "_" + i };
    for (let j = 0; j < fields.length; j++) {
      solutionObject[fields[j].name] = fields[j]?.default ? fields[j]?.default : "";
    }
    solutions.push(solutionObject);
  }
  let finalData = {
    ingredientId: finalIngredientData,
    order: id,
    solutions: solutions,
    type: type == "Manual",
  };

  return finalData;
};

const runRecipe = async (id: any) => {
  let resp = await ApiOperations.get(
    `${config.apiGateway.recipeURL}/api/v1/recipes/run/` + id,
    (status, data) => {
      return data;
    }
  );

  return resp;
};

const getStatus = async (id: any) => {
  let x = await ApiOperations.get(
    `${config.apiGateway.recipeURL}/api/v1/recipes/status/${id}`,
    (status, data) => {
      if (status > 400) {
        return "";
      }
      return data;
    }
  );

  return x;
};

const pauseRecipe = async (id: any) => {
  let x = await ApiOperations.post(
    `${config.apiGateway.recipeURL}/api/v1/recipes/stop/` + id,
    {},
    (status, data) => {
      return data;
    }
  );

  return x;
};

const recipeService = {
  getRecipe,
  copyRecipe,
  deleteRecipe,
  listRecipes,
  viewIngredientFields,
  CreateScheduledRecipe,
  CreateManualRecipe,
  editManualRecipe,
  editScheduledRecipe,
  checkPermission,
  runRecipe,
  getStatus,
  pauseRecipe,
};

export default recipeService;
